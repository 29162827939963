<!-- eslint-disable max-len -->
<template>
  <div
    id="modal-show-notification"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-delete-farm"
  >
    <div
      class="uk-modal-dialog uk-modal-body" 
      style="width: 478px;"
    >
      <div class="uk-modal-header">
        <h4 class="uk-text-title uk-text-bold">
          Notifikasi Terbaru
        </h4>
      </div>

      <div
        id="content"
        uk-overflow-auto
        :style="`${notifications.length > 1 ? 'height: 480px' : ''}`"
        @scroll="scrolling"
      >
        <template v-if="notifications">
          <template v-if="notifications.length > 0">
            <div
              v-for="(modalShowNotif, i) in notifications"
              :key="i"
            >
              <h4
                class="uk-text-title uk-text-left uk-margin-small-top msb notification uk-margin-remove-bottom "
              >
                Perubahan <span class="uk-text-title uk-text-bold">{{ 'No. Handphone' }}</span>
              </h4>
              <h5 class="uk-text-left uk-margin-remove-top uk-margin-remove-bottom notification msb">
                User ID <span class="uk-text-title uk-text-bold">{{ modalShowNotif.user_code }}</span> 
                dengan Nama <span class="uk-text-title uk-text-bold">{{ modalShowNotif.user_name }}</span> <br>
                telah mengubah <span class="uk-text-title uk-text-bold">{{ 'No. Handphone' }}</span> 
                <span class="uk-text-title uk-text-bold">{{ ' +62 ' + modalShowNotif.before }}</span> Menjadi 
                <span class="uk-text-title uk-text-bold">{{ ' +62 ' + modalShowNotif.after }}</span>
              </h5>
              <h5 class="uk-text-muted uk-text-left uk-margin-remove-top">
                {{ dateParanjeCompleteString(modalShowNotif.created_at) }}
              </h5>
              <hr>
            </div>
          </template>
          <template v-if="notifications.length < 1">
            <h5 class="uk-margin-top uk-text-center uk-text-muted">
              Tidak ada notifikasi terbaru
            </h5>
          </template>
        </template>
        <template v-if="is_loading && bottom">
          <h5 class="uk-margin-top uk-text-center uk-text-muted">
            Loading . . .
          </h5>
        </template>
        <template v-else>
          <h5 class="uk-margin-top uk-text-center uk-text-muted">
            <hr>
          </h5>
        </template>
      </div>

      <button
        class="uk-button uk-button-default uk-modal-close uk-text-center uk-margin-top"
        type="button"
      >
        Tutup
      </button>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import { dateParanjeCompleteString } from '@/utils/formater'

export default {
  data() {
    return {
      is_loading: false,
      images: PREFIX_IMAGE,
      notifications: {},
      bottom: {},
      limitVal: 10
    }
  },
  computed : {
    ...mapGetters({
      modalShowNotif: 'user/modalShowNotif',
      limitNotif: 'user/limitNotif'
    })
  },
  watch:{
    async modalShowNotif(){
      this.is_loading = true 
      this.notifications = this.modalShowNotif ? this.modalShowNotif : 0
      this.is_loading = false
    }
  }, 
  async mounted(){
    this.is_loading = true
    await this.getNotifications({limit:this.limitVal})
    this.notifications = this.modalShowNotif ? this.modalShowNotif : 0
    this.is_loading = false
  },
  methods:{
    ...mapActions({
      getNotifications: 'user/listNotifications'
    }),
    async scrolling (e) {
      this.is_loading = true
      let clientHeight = e.target.clientHeight
      let scrollHeight = e.target.scrollHeight
      let scrollTop = e.target.scrollTop
      if(this.limitNotif != this.notifications.length){
        if (scrollHeight - (scrollTop + clientHeight) <= 1) {
          this.bottom = true
          await this.getNotifications({limit:this.limitVal+=10})
          this.is_loading = false
        } else {
          this.bottom = false
        }
      } else {
        this.is_loading = false
      }
    },
    dateParanjeCompleteString(date){
      return dateParanjeCompleteString(date)
    }
  }
}
</script>

<style scoped>
.uk-text-title {
  color: #025231;
}
.label-modal {
  padding-right: 40px;
}
.text-body-modal {
  font-size:16px;
  text-align: left;
}
.notification{
  font-size:16px
}
h4 .notification{
  font-size:24px;
}
.msb{
  margin-bottom: 8px;
}
</style>
