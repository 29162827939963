<template>
  <div
    id="modal-show-password"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-show-password"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <h4 class="uk-text-title">
        Password Yang Baru
      </h4>
      <div class="uk-grid">
        <div class="uk-width-1">
          <div class="uk-grid uk-grid-small">
            <div class="uk-width text-body-modal">
              Silahkan beritahu password yang baru kepada user.
            </div>
          </div>
          <div class="uk-grid uk-grid-small text-body-modal">
            <div class="uk-width-1-5">
              User
            </div>
            <div class="uk-width-3-5">
              <b>: {{ modalShowPassword.name }}</b>
            </div>
          </div>
          <div class="uk-grid uk-grid-small text-body-modal">
            <div class="uk-width-1-5">
              No HP
            </div>
            <div class="uk-width-3-5">
              <b>: {{ `62${modalShowPassword.phone}` }}</b>
            </div>
          </div>
          <div class="uk-grid uk-grid-small text-body-modal">
            <div class="uk-width-1-5">
              Password
            </div>
            <div class="uk-width-3-5">
              : <b class="uk-text-danger"> {{ modalShowPassword.temp_pass }}</b>
            </div>
          </div>
        </div>
      </div>

      <div style="padding-top:20px">
        <button
          class="uk-button uk-button-default uk-modal-close"
          type="button"
          :disabled="is_loading"
        >
          Tutup
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  data() {
    return {
      is_loading: false,
      images: PREFIX_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      modalShowPassword: 'user/modalShowPassword'
    })
  }
}
</script>

<style scoped>
.uk-text-title {
  color: #025231;
}
.label-modal {
  padding-right: 40px;
}
.text-body-modal {
  font-size:16px;
  text-align: left;
}
</style>
