<template>
  <table
    id="tableNotif"
    style="display: none;"
  >
    <thead>
      <tr>
        <th
          rowspan="2"
        >
          No
        </th>
        <th
          rowspan="2"
        >
          User ID
        </th>
        <th
          rowspan="2"
        >
          Nama
        </th>
        <th
          colspan="2"
        >
          No. Handphone Sebelum
        </th>
        <th
          colspan="2"
        >
          No. Handphone Sesudah
        </th>
        <th
          rowspan="2"
        >
          Tanggal Diubah
        </th>
        <th
          rowspan="2"
        >
          Diubah Oleh
        </th>
        <th
          rowspan="2"
        >
          Status
        </th>
      </tr>
    </thead>
    <tbody
      v-if="dataExports"
    >
      <tr 
        v-for="(item, i) in dataExports.data "
        :key="i"
      >
        <td>
          {{ i+1 }}
        </td>
        <td>
          {{ item.user_code }}
        </td>
        <td>
          {{ item.user_name }}
        </td>
        <td>
          {{ item.before }}
        </td>
        <td>
          {{ item.after }}
        </td>
        <td>
          {{ dateThreeLetter(item.created_at) }}
        </td>
        <td>
          {{ item.user_name }}
        </td>
        <td>
          {{ item.status == 'SUCCESS' ? 'Berhasil' : 'Tidak Berhasil' }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {  dateStringThreeMonthLetter, dateStringMonthOfYear } from '@/utils/formater'

export default {
  props: {
    exportFunc: {
      required: true,
      type: Object
    }
  },
  data(){
    return {
      errorExportPeriode:false
    }        
  },
  computed : {
    ...mapGetters({
      dataExports: 'user/dataExports',
      metaUser: 'user/metaUser',
      roles: 'user/roles'
    })
  },
  watch: {
    async exportFunc(){
      await this.getNotificationsExport({period: dateStringMonthOfYear(this.exportFunc.exportPeriode)})
      this.exportDataLog()
    }
  },
  methods: {
    exportDataLog(){
      if(this.exportFunc){
        // Select rows from table_id
        var rows = document.querySelectorAll('table#' + this.exportFunc.tableId + ' tr')
        // Construct csv
        var csv = []
        for (var i = 0; i < rows.length; i++) {
          var row = [], cols = rows[i].querySelectorAll('td, th')
          for (var j = 0; j < cols.length; j++) {
            // Clean innertext to remove multiple spaces and jumpline (break csv)
            var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
            // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
            data = data.replace(/"/g, '""')
            // Push escaped string
            row.push('"' + data + '"')
          }
          csv.push(row.join(this.exportFunc.separator))
        }
        var csv_string = csv.join('\n')
        // Download it
        var filename = `Log History Nomor Handphone Bulan ${this.exportFunc.exportPeriode}`
        var link = document.createElement('a')
        link.style.display = 'none'
        link.setAttribute('target', '_blank')
        link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string))
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    ...mapActions({
      getNotificationsExport: 'user/exportNotifications'
    }),
    dateStringMonthOfYear(date){
      return dateStringMonthOfYear(date)
    },
    dateThreeLetter(d) {
      let date= d.split("-")
      let f = new Date(`${date[2]}/${date[1]}/${date[0]}`)
      return dateStringThreeMonthLetter(f.toString())
    }
  }
}
</script>
