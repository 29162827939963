<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table class="uk-table uk-table-striped uk-table-hover uk-table-divider">
        <thead class="thead-table-paranje">
          <tr>
            <th class="uk-table-small">
              <span class="uk-text-bold">UserID</span>
            </th>
            <th class="uk-table-small">
              <span class="uk-text-bold">Nama User</span>
            </th>
            <th class="uk-table-small">
              <span class="uk-text-bold">Role</span>
            </th>
            <th class="uk-table-small">
              <span class="uk-text-bold">No Handphone</span>
            </th>
            <th class="uk-table-small">
              <span class="uk-text-bold">Email</span>
            </th>
            <th class="uk-table-small">
              <span class="uk-text-bold">Tanggal Gabung</span>
            </th>
            <th class="uk-table-shrink uk-text-center">
              <span class="uk-text-bold">Status</span>
            </th>
            <th class="uk-table-small">
              <span class="uk-text-bold">Terakhir Diubah</span>
            </th>
            <th class="uk-table-small">
              <span class="uk-text-bold">Diubah Oleh</span>
            </th>
            <th class="uk-table-expand uk-text-center">
              <span class="uk-text-bold">Aksi</span>
            </th>
          </tr>
        </thead>
        <template v-if="!is_loading">
          <tbody v-if="users.meta.total_count>0">
            <tr
              v-for="(user, i) in users.data"
              :key="i"
            >
              <td>{{ user.code || '-' }}</td>
              <td>{{ user.name || '-' }}</td>
              <td>{{ user.role || '-' }}</td>
              <td>{{ user.phone || '-' }}</td>
              <td>{{ user.email || '-' }}</td>
              <td>{{ user.join_date ? formatDate(user.join_date) : '-' }}</td>
              <td>
                <label-status :status="user.status" />
              </td>
              <td>{{ user.updated_at ? formatDate(user.updated_at) : '-' }}</td>
              <td>{{ user.updated_by || '-' }}</td>
              <td class="uk-flex uk-flex-center">
                <img
                  v-if="user.is_reset"
                  v-lazy="`${images}/icon/password-lock.svg`"
                  alt=""
                  class="uk-margin-small-right img-click"
                  @click="showModalReset(user)"
                >
                <img
                  v-else
                  v-lazy="`${images}/icon/key-icon.svg`"
                  alt=""
                  class="uk-margin-small-right"
                >
                <img
                  v-if="isCanAccessUser(`view`, `User`)"
                  v-lazy="`${images}/icon/eye.svg`"
                  alt=""
                  class="img-click"
                  @click="showDetailUser(user.id)"
                >
              </td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="10"
          />
        </template>
        <template v-else>
          <loading-table :colspan="10" />
        </template>
      </table>
    </div>
    <pagination
      :total-data="users.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="metaUser.page"
      :current-limit="metaUser.limit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Pagination from '@/components/globals/Pagination'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import LabelStatus from '@/components/globals/LabelStatus'
import { STATUS } from '@/utils/constant'
import { dateUtcParanjeString } from '@/utils/formater'
import { PREFIX_IMAGE } from '@/utils/constant'
import { isCanAccess } from '@/utils/auth'

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination,
    LabelStatus
  },
  data() {
    return {
      is_loading: true,
      status: STATUS,
      images: PREFIX_IMAGE
    }
  },
  computed : {
    ...mapGetters({
      users: 'user/users',
      metaUser: 'user/metaUser'
    })
  },
  watch: {
    async metaUser() {
      this.is_loading = true
      await this.getUser(this.metaUser)
      this.is_loading = false
    }
  },
  async mounted() {
    await this.getUser(this.metaUser)
    this.is_loading = false
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getUser: 'user/getUser'
    }),
    ...mapMutations({
      setMetaUser: 'user/SET_META_USER',
      setModalShowPassword: 'user/SET_MODAL_SHOW_PASSWORD'
    }),
    changeLimit(e) {
      this.setMetaUser({
        ...this.metaUser,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMetaUser({
        ...this.metaUser,
        page: value
      })
    },
    showDetailUser(id) {
      this.$router.push(`/admin/user/detail/${id}`)
    },
    showModalReset(user) {
      window.UIkit.modal('#modal-show-password').show()
      this.setModalShowPassword(user)
    }
  }
}
</script>
