<template>
  <div>
    <div class="uk-form-horizontal filter-container">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-first-column">
          <label
            class="uk-form-label filter-margin"
            for="form-horizontal-text"
          >Cari</label>
          <div class="uk-form-controls">
            <input
              id="form-horizontal-text"
              v-model="metaFilter.name"
              class="uk-input"
              type="text"
              placeholder="Cari user id, nama user, no hp, email..."
            >
          </div>
        </div>
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
          <label
            class="uk-form-label filter-margin"
            for="status"
          >
            Status
          </label>
          <div class="uk-form-controls">
            <multiselect
              id="status"
              v-model="statusChoose"
              v-validate="'required'"
              name="status"
              label="name"
              track-by="name"
              placeholder="Semua"
              :options="userstatus"
              open-direction="bottom"
              :close-on-select="true"
              :max-height="500"
              :show-no-result="true"
            />
          </div>
        </div>
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small filter-margin"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-first-column">
          <label
            class="uk-form-label filter-margin"
            for="role"
          >
            Role
          </label>
          <div class="uk-form-controls">
            <multiselect
              id="role"
              v-model="roleChoose"
              v-validate="'required'"
              name="role"
              placeholder="Semua"
              open-direction="bottom"
              label="name"
              track-by="name"
              :options="roleData"
              :close-on-select="true"
              :max-height="500"
            />
          </div>
        </div>

        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
          <label
            class="uk-form-label filter-margin"
            for="form-horizontal-text"
          >Tanggal Diubah</label>
          <div class="uk-form-controls">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-2-5@s">
                <datepicker
                  ref="createdStart"
                  v-model="metaFilter.updated_start"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                />  
              </div>
              <div class="uk-width-1-5@s uk-text-center filter-padding">
                to
              </div>
              <div class="uk-width-2-5@s">
                <datepicker
                  ref="createdEnd"
                  v-model="metaFilter.updated_end"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                />  
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small filter-margin"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
          <label
            class="uk-form-label filter-margin"
            for="form-horizontal-text"
          >Tanggal Gabung</label>
          <div class="uk-form-controls">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-2-5@s">
                <datepicker
                  ref="createdStart"
                  v-model="metaFilter.join_start"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                />  
              </div>
              <div class="uk-width-1-5@s uk-text-center filter-padding">
                to
              </div>
              <div class="uk-width-2-5@s">
                <datepicker
                  ref="createdEnd"
                  v-model="metaFilter.join_end"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                />  
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-text-right">
          <button
            class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
            @click="clearFilter()"
          >
            <span
              uk-icon="icon: close; ratio: 0.9"
              class="uk-margin-small-right"
            />Clear
          </button>
          <button
            class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
            @click="handleFind()"
          >
            <span
              uk-icon="icon: search; ratio: 0.9"
              class="uk-margin-small-right"
            />Cari
          </button>
        </div>
      </div>
    </div>



    <div
      class="uk-child-width-1-2 uk-text-left uk-margin-small-top"
      uk-grid
    >
      <div>
        <router-link
          v-if="isCanAccessUser(`add`, `User`)"
          class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
          to="/admin/user/add"
        >
          <span
            uk-icon="icon: plus; ratio: 0.7"
            class="uk-margin-small-right"
          />Tambah User
        </router-link>
      </div>

      <div>
        <div
          class="
          uk-flex uk-flex-center uk-flex-center@s uk-flex-left@m uk-flex-right@l uk-flex-right@xl uk-flex-middle"
          uk-grid
        >
          <button
            v-if="isReadStatusFalse"
            class="uk-width-1-1@s uk-button-default uk-button uk-square uk-remove-margin"
            @click="showModalNotif()"
          >
            <img
              :src="`${images}/icon/bell-box-red-dot.svg`"
              alt=""
              class="uk-margin-small-right"
            >
          </button>
          <button
            v-else
            class="uk-width-1-1@s uk-button-default uk-button uk-square uk-remove-margin"
            @click="showModalNotif()"
          >
            <img
              :src="`${images}/icon/bell-box.svg`"
              alt=""
              class="uk-margin-small-right"
            >
          </button>
          <label
            class="uk-form-label text-periode"
          > Pilih Periode</label>
          <div
            class="uk-padding-remove-left"
          >
            <datepicker
              v-model="exportPeriode"
              class="uk-padding-remove-left uk-margin-small-right"
              input-class="uk-input"
              placeholder="Masukkan Periode"
              minimum-view="month"
              format="MMMM yyyy"
              open
            />
          </div>
          <button
            class=" uk-button"
            :class="`${exportPeriode ? 'uk-button-primary' : 'uk-button-disable'}`"
            type="button"
            :disabled="!exportPeriode"
            @click="exportLogHistory()"
          >
            <img
              v-lazy="`${images}/icon/${exportPeriode ? 'export-white' : 'export'}.svg`"
              alt=""
              class=""
            >
            Export
          </button>
        </div>
        <export-template :export-func="exportProps" />
      </div>
    </div>
  </div>
</template>


<script>
import { PREFIX_IMAGE } from '@/utils/constant'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import Datepicker from 'vuejs-datepicker'
import { dateString } from '@/utils/formater'
import { isCanAccess } from '@/utils/auth'
import exportTemplate from './exportTemplate.vue'

export default {
  components: {
    Datepicker,
    exportTemplate
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      roleChoose: '',
      statusChoose:'',
      metaFilter: {
        name: '',
        status: '',
        role: ''
      },
      metaFilterRole: {
        status: 'true'
      },
      exportPeriode:'',
      roleData:[],
      userstatus:[
        {name:'Aktif', value:'true'},
        {name:'Tidak Aktif', value:'false'}
      ],
      exportProps:{},
      isReadStatusFalse: null
    }
  },
  computed : {
    ...mapGetters({
      metaUser: 'user/metaUser',
      roles: 'user/roles',
      isReadValue: 'user/isReadNotification'
    })
  },
  watch: {
    'exportPeriode'(){
      this.exportPeriode = this.exportPeriode.toLocaleString('default', { month: 'long', year: 'numeric'})
    },
    'metaFilter.join_start'() {
      this.metaFilter.join_start = this.dateFormatFilter(this.metaFilter.join_start)
    },
    'metaFilter.join_end'() {
      this.metaFilter.join_end = this.dateFormatFilter(this.metaFilter.join_end)
    },
    'metaFilter.updated_start'() {
      this.metaFilter.updated_start = this.dateFormatFilter(this.metaFilter.updated_start)
    },
    'metaFilter.updated_end'() {
      this.metaFilter.updated_end = this.dateFormatFilter(this.metaFilter.updated_end)
    }
  },
  async mounted() {
    await this.getIsRead()

    // apakah status telah terbacanya false//
    this.isReadStatusFalse = this.isReadValue == true ? true : false

    await this.getRole({is_all: true}),
    this.roleData = this.roles ? this.roles : []
  },
  methods: {
    exportLogHistory() {
      this.exportProps={tableId: 'tableNotif', separator: ',', exportPeriode: this.exportPeriode}
    },
    dateFormatFilter(date) {
      return dateString(date)
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    ...mapActions({
      getIsRead: 'user/isRead',
      getRole: 'user/getRole',
      getNotifications: 'user/listNotifications'
    }),
    ...mapMutations({
      setMetaUser: 'user/SET_META_USER'
    }),
    async showModalNotif() {
      this.isReadStatusFalse = false
      window.UIkit.modal('#modal-show-notification').show()
    },
    async clearFilter() {
      this.metaFilter.join_start = ''
      this.metaFilter.join_end = ''
      this.metaFilter.updated_start = ''
      this.metaFilter.updated_end = ''
      this.metaFilter.name = ''
      this.metaFilter.status = ''
      this.roleChoose = ''
      this.statusChoose=''
      await this.handleFind()
    },
    async handleFind() {
      this.metaFilter.page = 1
      this.metaFilter.role = this.roleChoose ? this.roleChoose.name ? this.roleChoose.name : '' : ''
      this.metaFilter.status = this.statusChoose ? this.statusChoose.value ? this.statusChoose.value : '' : ''
      this.setMetaUser({
        ...this.metaUser,
        ...this.metaFilter
      })
    }
  }
}

</script>

<style scoped>
.uk-square
{
  border: none;
  width: 40px;
  padding-top: 5px;
  padding-left: 0px;
  padding-right: 0px;
  margin-right: 17px;
}
.text-periode{
  padding-top: 10px;
  padding-left: 0px;
  padding-right: 10px;
  bottom: 0px;
  height: 21px;

  font-family: 'ClanOT';
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 150%;
/* identical to box height, or 21px */

  display: flex;
  align-items: flex-end;

/* Brand / Primary */

  color: #025231;


/* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
</style>
